import { Link } from 'gatsby'
import React from 'react'
import Icon from './icons'
import { cn } from '../lib/helpers'

import styles from './background-image.module.css'

const BackgroundImage = ({}) => (
    <div className={styles.image}>
        <div className={styles.intro}>
            <h1>Welcome to Sammy's 3D Tours!</h1>
            <div>Let's transform your business to take advantage of all the digital world has to offer.</div>
        </div>
    </div>
)

export default BackgroundImage